//frontend/src/components/ProjectPage.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PROJECTS, Project } from '../projectConstants';

const ProjectsPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState<Project[]>(PROJECTS);

  useEffect(() => {
    const results = PROJECTS.filter(project =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.technologies.some(tech => tech.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredProjects(results);
  }, [searchTerm]);

  return (
    <div className="bg-gray-900 min-h-screen">
      <div className="container mx-auto px-4 py-8 pt-24"> {/* Added pt-24 for navbar space */}
        <h1 className="text-4xl font-bold text-white mb-8">All Premium Software and Hardware Projects</h1>
        <div className="mb-8">
          <input
            type="text"
            placeholder="Search projects..."
            className="w-full px-4 py-2 rounded-md bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {filteredProjects.map((project: Project, index: number) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            >
              <h3 className="text-2xl font-semibold mb-3 text-white">{project.name}</h3>
              <p className="text-gray-400 mb-4">{project.description}</p>
              <div className="mb-4">
                <strong className="text-white">Technologies:</strong>{" "}
                <span className="text-gray-400">{project.technologies.join(", ")}</span>
              </div>
              <div className="mb-4">
                <strong className="text-white">Price:</strong>{" "}
                <span className="text-gray-400">₹{project.price}</span>
              </div>
              <Link
                to={`/project/${index}`}
                className="inline-block bg-blue-500 text-white px-5 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300"
              >
                More Details
              </Link>
            </div>
          ))}
        </div>
        {filteredProjects.length === 0 && (
          <p className="text-white text-center mt-8">No projects found matching your search.</p>
        )}
      </div>
    </div>
  );
};

export default ProjectsPage;