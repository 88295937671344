// src/components/ArticleList.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';
import { ARTICLES } from '../projectConstants';

// Helper function to strip HTML tags and get plain text
const stripHtml = (html: string) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const ArticleList: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen pt-5">
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Premium Tech Articles📰</h1>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {ARTICLES.map((article) => (
            <div key={article.id} className="bg-white shadow rounded-lg overflow-hidden transition-transform duration-300 hover:scale-102">
              <div className="p-4">
                <h2 className="text-lg font-semibold mb-2 text-gray-800">{article.title}</h2>
                <p className="text-sm text-gray-600 mb-3">
                  {stripHtml(article.content).substring(0, 100)}...
                </p>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-gray-500 flex items-center">
                    <FaClock className="mr-1" size={12} />
                    {article.readTime}
                  </span>
                  <Link 
                    to={`/article/${article.id}`} 
                    className="text-blue-600 hover:text-blue-800 font-medium hover:underline"
                  >
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticleList;