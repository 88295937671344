import React from 'react';
import { FaPython, FaJava, FaRProject, FaNodeJs, FaReact } from 'react-icons/fa';
import { DiPostgresql } from "react-icons/di";
import { TbBrandCpp } from "react-icons/tb";
import { SiTensorflow } from "react-icons/si";
import { SiIbm } from "react-icons/si";
import { SiApachespark } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { SiOpenai } from "react-icons/si";
import { SiAnthropic } from "react-icons/si";
import { SiMicrosoftexcel } from "react-icons/si";
import { SiTableau } from "react-icons/si";
import { SiPowerbi } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";
import { SiDocker } from "react-icons/si";
import { FaGitAlt } from "react-icons/fa6";
import { SiFlask } from "react-icons/si";
import { SiJulia } from "react-icons/si"; // Import relevant icons

interface PlaylistProps {
  icon: React.ReactNode;
  title: string;
  playlistUrl: string;
}

const PlaylistCard: React.FC<PlaylistProps> = ({ icon, title, playlistUrl }) => (
  <a
    href={playlistUrl}
    target="_blank"
    rel="noopener noreferrer"
    className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105"
  >
    <div className="text-6xl text-blue-500 mb-4">{icon}</div>
    <h3 className="text-xl font-bold text-gray-700">{title}</h3>
  </a>
);

const YouTubePlaylists: React.FC = () => {
  const playlists = [
    {
      icon: <FaPython />,
      title: 'Python Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHX9FBsHptHxcLNgovLQ0tky',
    },
    {
      icon: <DiPostgresql />,
      title: 'SQL Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHUrvG-M2eo0zgZV6Sq6EdaT',
    },
    {
      icon: <FaRProject />,
      title: 'R Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHWztWHptPiqSHZD8EzFV1sN',
    },
    {
      icon: <SiIbm />,
      title: 'As400 tutorials',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHVWWmYX1Klji7QUk_PQhq0t',
    },
    {
      icon: <SiScikitlearn />,
      title: 'Scikit-learn tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHUBfW6ZKS2tldUwHB4_10k3',
    },
    {
      icon: <SiTensorflow />,
      title: 'Tensorflow tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHVDp-QghbmBI8iFnSng3XWV',
    },
    {
      icon: <SiOpenai />,
      title: 'OpenAI Chatgpt tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXwlNWrFoqkPdUriVux64pn',
    },
    {
      icon: <SiAnthropic />,
      title: 'Anthropic tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXwlNWrFoqkPdUriVux64pn',
    },
    {
      icon: <FaJava />,
      title: 'Java Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHW4R-dHB646VVJEOGGHaCMI',
    },
    {
      icon: <FaNodeJs />,
      title: 'Node.js Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHUkJRh-UJDNeuhC1RwTUvb1',
    },
    {
      icon: <FaReact />,
      title: 'React Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHUJYaQUpxVNohVqpWxQ1a2F',
    },
    {
      icon: <TbBrandCpp />,
      title: 'C++ Programming',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHU6gtsz4Qn0orEvMCNMduM9',
    },
    {
      icon: <SiApachespark />,
      title: 'PySpark tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXDMqD_Xk8vpCgaNJCBTJgn',
    },
    {
      icon: <FaAws />,
      title: 'AWS cloud tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHWAvrePpoKU5n61EXC2LvO0',
    },
    {
      icon: <SiMicrosoftexcel />,
      title: 'Microsoft Excel tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXsrDNWY9fV_V5T8Iq0hhSi',
    },
    {
      icon: <SiTableau />,
      title: 'Tableau tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXLgkeDv7GC1XBvGRWh2Rt0',
    },
    {
      icon: <SiPowerbi />,
      title: 'Power BI tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXrELHFUZub7VmdSe6hKxhi',
    },
    {
      icon: <SiDocker />,
      title: 'Docker tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHUYloT3G5t48iIQKXBcj8kc',
    },
    {
      icon: <FaGitAlt />,
      title: 'Git tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXEHPmKcENnnHtrsMgyf-Mb',
    },
    {
      icon: <SiFlask />,
      title: 'Flask tutorial',
      playlistUrl: 'https://www.youtube.com/playlist?list=PL3W4xRdnQJHXzyqvV4oQiq8B_6LXNPHE9 ',
    },
    {
      icon: <SiJulia />,
      title: 'Julia tutorial',
      playlistUrl: 'https://youtu.be/avFwleVlVUA ',
    },

  ];

  return (
    <section id="youtube" className="py-20 bg-black">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center text-white">Industry-Ready Coding: Master the Skills That Matter🖥️</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {playlists.map((playlist, index) => (
            <PlaylistCard
              key={index}
              icon={playlist.icon}
              title={playlist.title}
              playlistUrl={playlist.playlistUrl}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default YouTubePlaylists;