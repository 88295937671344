// // src/components/Testimonials.tsx
import React, { useRef, useState, useEffect } from 'react';
import testiImage from '../assets/himanshu.png';
import testiImage2 from '../assets/akash.png';
import testiImage3 from '../assets/harsh.png';
import testiImage7 from '../assets/vidhi.jpeg';
import testiImage4 from '../assets/neha.png';
import testiImage5 from '../assets/sahil.png';
import testiImage6 from '../assets/abhishake.png';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface Testimonial {
  name: string;
  position: string;
  feedback: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Himanshu",
    position: "Software Engineer@Johnson Controls",
    feedback: "I did purchase his project and it was a great experience. He is a very skilled and dedicated developer. I highly recommend him !",
    image: testiImage,
  },
  {
    name: "Akash Kothari",
    position: "Data Scientist@Deloitte",
    feedback: "I watch his youtube videos and they are really helpful. I would recommend his videos to anyone who is looking to learn about machine learning and data science.",
    image: testiImage2,
  },
  {
    name: "Harsh Bhatt",
    position: "Business Analyst@Morningstar",
    feedback: "His Python and SQL skills are really good. I would recommend him to anyone who is looking to learn about machine learning and data science.",
    image: testiImage3,
  },
  {
    name: "Vidhi",
    position: "Business Analyst@Solutionec",
    feedback: "Coming from a non-CS background pharmacy, I found his content really helpful. Specially for analytics, he has a great way of explaining things.",
    image: testiImage7,
  },
  {
    name: "Neha Alam",
    position: "Data Engineer@Bank of America",
    feedback: "I loved his pyspark and python skills. Must watch for anyone looking to skill up in pyspark and python",
    image: testiImage4,
  },
  {
    name: "Sahil Sharma",
    position: "Software Engineer@Morgan Stanley",
    feedback: "I have worked with him previously and he is a very skilled. Also purchased his project and it was a great experience",
    image: testiImage5,
  },
  {
    name: "Adv.Abhishek Boricha",
    position: "Lawyer@High Court of Bombay",
    feedback: "He helped me make a RAG pipeline for my law firm. I would recommend him to anyone who is looking to learn about machine learning and data science.",
    image: testiImage6,
  },
  // Add more testimonials as needed
];

const Testimonials: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const updateArrows = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 10); // 10px threshold
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', updateArrows);
      window.addEventListener('resize', updateArrows);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', updateArrows);
      }
      window.removeEventListener('resize', updateArrows);
    };
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      scrollRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
    }
  };

  return (
    <section id="testimonials" className="py-20 bg-black relative">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-4xl font-bold mb-12 text-white">Client Testimonials</h2>
        <div className="relative">
          <div
            ref={scrollRef}
            className="flex overflow-x-auto scrollbar-hide snap-x snap-mandatory -mx-4 px-4"
            style={{ scrollSnapType: 'x mandatory', WebkitOverflowScrolling: 'touch' }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex-none w-full sm:w-1/2 lg:w-1/3 px-4 snap-start"
              >
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 h-full">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-24 h-24 rounded-full mx-auto mb-4 border-4 border-gray-700"
                  />
                  <h3 className="text-xl font-semibold mb-1 text-white">{testimonial.name}</h3>
                  <p className="text-sm text-gray-400 mb-2">{testimonial.position}</p>
                  <p className="text-gray-400">{testimonial.feedback}</p>
                </div>
              </div>
            ))}
          </div>
          {showLeftArrow && (
            <button
              onClick={() => scroll('left')}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full text-white hover:bg-gray-700 transition-colors duration-300 hidden sm:block"
              aria-label="Previous testimonial"
            >
              <ChevronLeft size={24} />
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => scroll('right')}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 p-2 rounded-full text-white hover:bg-gray-700 transition-colors duration-300 hidden sm:block"
              aria-label="Next testimonial"
            >
              <ChevronRight size={24} />
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;