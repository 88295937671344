//src/components/digitalDelivery.tsx
import React from 'react';
import { FaCloudDownloadAlt, FaClock, FaKey, FaLaptop, FaDownload, FaExclamationTriangle, FaExchangeAlt, FaEnvelope } from 'react-icons/fa';

const PolicySection: React.FC<{ title: string; content: string; icon: React.ReactNode }> = ({ title, content, icon }) => (
  <div className="mb-8 bg-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className="text-purple-400 mr-4">{icon}</div>
      <h2 className="text-2xl font-semibold text-purple-300">{title}</h2>
    </div>
    <p className="text-gray-300">{content}</p>
  </div>
);

const DigitalDeliveryPolicy: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-black to-gray-900 min-h-screen py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-12 text-white text-center">Digital Delivery Policy</h1>
        <div className="max-w-4xl mx-auto">
          <PolicySection
            icon={<FaCloudDownloadAlt size={24} />}
            title="Delivery Method"
            content="All our products are delivered electronically. Upon successful payment, you will receive access to download your purchased digital content or receive login credentials for online services."
          />
          <PolicySection
            icon={<FaClock size={24} />}
            title="Delivery Timeframe"
            content="Digital products are typically available for download immediately after the payment is confirmed. In some cases, it may take up to 24 hours for your purchase to be processed and the digital content to be made available."
          />
          <PolicySection
            icon={<FaKey size={24} />}
            title="Access to Products"
            content="You will receive an email with instructions on how to access your purchased digital content. This may include download links, login credentials, or activation codes."
          />
          <PolicySection
            icon={<FaLaptop size={24} />}
            title="Technical Requirements"
            content="Please ensure that your device meets the minimum technical requirements for accessing and using our digital products. These requirements will be listed on the product page or in the product description."
          />
          <PolicySection
            icon={<FaDownload size={24} />}
            title="Download Limits"
            content="Some digital products may have limits on the number of times they can be downloaded or the duration of access. These limits, if any, will be clearly stated on the product page."
          />
          <PolicySection
            icon={<FaExclamationTriangle size={24} />}
            title="Issues with Digital Delivery"
            content="If you experience any issues with accessing or downloading your purchased digital content, please contact our customer support team at shabbirg89@gmail.com within 7 days of your purchase."
          />
          <PolicySection
            icon={<FaExchangeAlt size={24} />}
            title="Refunds for Digital Products"
            content="Due to the nature of digital products, all sales are final unless otherwise required by law. Please refer to our Refund Policy for more detailed information about refunds on digital products."
          />
          <PolicySection
            icon={<FaEnvelope size={24} />}
            title="Contact Us"
            content="If you have any questions about our Digital Delivery Policy, please contact us at shabbirg89@gmail.com."
          />
        </div>
        <p className="text-center text-gray-400 mt-12">Last updated: {new Date().toDateString()}</p>
      </div>
    </div>
  );
};

export default DigitalDeliveryPolicy;