// src/components/ProjectDetails.tsx

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PROJECTS, Project } from '../projectConstants';
import { FaGithub, FaDownload, FaArrowLeft } from 'react-icons/fa';

const ProjectDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const project: Project | undefined = id ? PROJECTS[Number(id)] : undefined;
  const [videoError, setVideoError] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';

    if (id) {
      const paidProjects = JSON.parse(localStorage.getItem('paidProjects') || '{}');
      if (paidProjects[id] && paidProjects[id].paid) {
        setIsPaid(true);
        setDownloadLink(paidProjects[id].downloadLink);
      }
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [id]);

  if (!project || !id) {
    return <div className="text-white text-center py-20">Project not found</div>;
  }

  const handleVideoError = () => {
    setVideoError(true);
  };

  const handlePurchaseClick = () => {
    navigate(`/payment/${id}`);
  };

  return (
    <div className="bg-gray-900 min-h-screen pt-20 pb-10">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-8 text-white">{project.name}</h1>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Project Overview</h2>
              <p className="text-xl text-gray-300 mb-6">{project.description}</p>
              <p className="text-lg text-gray-300">{project.longDescription}</p>
            </section>

            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Key Features</h2>
              <ul className="list-disc list-inside text-gray-300 grid grid-cols-1 md:grid-cols-2 gap-4">
                {project.features.map((feature: string, index: number) => (
                  <li key={index} className="text-lg">{feature}</li>
                ))}
              </ul>
            </section>

            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Technologies Used</h2>
              <div className="flex flex-wrap gap-3">
                {project.technologies.map((tech: string, index: number) => (
                  <span key={index} className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                    {tech}
                  </span>
                ))}
              </div>
            </section>

            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Implementation Details</h2>
              <p className="text-lg text-gray-300">{project.implementationDetails}</p>
            </section>

            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Use Cases</h2>
              <ul className="list-disc list-inside text-gray-300">
                {project.useCases.map((useCase: string, index: number) => (
                  <li key={index} className="text-lg mb-2">{useCase}</li>
                ))}
              </ul>
            </section>

            <section className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-3xl font-semibold mb-4 text-white">Benefits</h2>
              <ul className="list-disc list-inside text-gray-300">
                {project.benefits.map((benefit: string, index: number) => (
                  <li key={index} className="text-lg mb-2">{benefit}</li>
                ))}
              </ul>
            </section>
          </div>

          <div className="lg:col-span-1">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-white">Project Demo</h2>
              {!videoError ? (
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src={project.youtubeUrl.replace("youtu.be/", "www.youtube.com/embed/")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                    onError={handleVideoError}
                    title={`${project.name} Demo`}
                  ></iframe>
                </div>
              ) : (
                <div className="bg-gray-700 p-4 rounded-lg text-white">
                  <p>Sorry, the video couldn't be loaded. You can view it directly on YouTube:</p>
                  <a 
                    href={project.youtubeUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:underline"
                  >
                    Watch on YouTube
                  </a>
                </div>
              )}
            </div>

            <div className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
              <h2 className="text-2xl font-semibold mb-4 text-white">Pricing</h2>
              <p className="text-3xl font-bold text-white mb-2">
                ₹{project.price}
              </p>
              {!isPaid ? (
                <button 
                  onClick={handlePurchaseClick}
                  className="block w-full text-center bg-blue-500 text-white px-5 py-3 rounded-full hover:bg-blue-600 transition-colors duration-300 mb-4"
                >
                  Purchase Now
                </button>
              ) : (
                <>
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-center bg-green-500 text-white px-5 py-3 rounded-full hover:bg-green-600 transition-colors duration-300 mb-4"
                  >
                    <FaGithub className="inline mr-2" /> View on GitHub
                  </a>
                  {downloadLink && (
                    <a 
                      href={downloadLink} 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full text-center bg-purple-500 text-white px-5 py-3 rounded-full hover:bg-purple-600 transition-colors duration-300 mb-4"
                    >
                      <FaDownload className="inline mr-2" /> Download Project Files
                    </a>
                  )}
                </>
              )}
              <Link 
                to="/projects"
                className="block w-full text-center bg-gray-700 text-white px-5 py-3 rounded-full hover:bg-gray-600 transition-colors duration-300"
              >
                <FaArrowLeft className="inline mr-2" /> Back to Projects
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;