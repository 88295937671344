//src/components/about
import React from 'react';
import { FaGithub, FaLinkedin, FaEnvelope, FaCode, FaServer, FaBrain, FaBriefcase, FaYoutube, FaGraduationCap } from 'react-icons/fa';
import { SiTensorflow, SiPytorch, SiScikitlearn, SiPandas, SiNumpy, SiMysql, SiAmazon, SiTableau } from 'react-icons/si';

const About: React.FC = () => {
  return (
    <div className="bg-black text-gray-300 min-h-screen py-20">
      <div className="container mx-auto px-4 max-w-4xl">
        <header className="text-center mb-12">
          <h1 className="text-5xl font-bold mb-4 text-white">Shabbir Governor</h1>
          <h2 className="text-2xl font-semibold mb-6 text-gray-400">Full Stack & Data Science Professional</h2>
        </header>
        
        <section className="mb-12 bg-gray-900 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
            <FaBrain className="mr-2 text-gray-400" /> Summary
          </h3>
          <p className="text-gray-300 leading-relaxed">
            A highly skilled and innovative Data Science professional with over 5+ years of experience in Generative AI, Machine Learning, Deep Learning, and Natural Language Processing (NLP). My expertise spans across Insurtech, AdTech & Broking with a focus on leveraging AI to enhance predictive capabilities and optimize operational performance. I am adept at deploying advanced models, leading cross-functional teams, and driving technological innovation.
          </p>
        </section>

        <section className="mb-12 bg-gray-900 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
            <FaBriefcase className="mr-2 text-gray-400" /> Professional Experience
          </h3>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-white">Lead Generative AI Engineer</h3>
              <p className="text-gray-400">Angel Broking | April 2024 - Present</p>
              <ul className="list-disc list-inside text-gray-300 mt-2">
                <li>Developed a comprehensive Creative Design Tool, optimizing ₹7 crore+ ad spend, improving campaign targeting and efficiency.</li>
                <li>Built a backend infrastructure supporting RAG (Retrieve, Augment, Generate) pipelines, streamlining document extraction, summarization, and Q&A for cross-departmental decision-making.</li>
                <li>Engineered AI-driven stock recommendation systems, utilizing Prompt Engineering and advanced language models to suggest top-performing stocks and mutual funds, increasing portfolio performance for users.</li>
                <li>Spearheaded collaborative efforts with data scientists and financial analysts to enhance ML model accuracy in stock recommendations, boosting user engagement.</li>
                <li>Automated key business workflows using NLP-powered document processing, reducing manual work hours by 30% and improving data accessibility.</li>
                <li>Integrated financial sentiment analysis models, enabling deeper market insights and refined stock and mutual fund recommendations.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Senior Data Scientist</h3>
              <p className="text-gray-400">BLKBOX.AI | April 2021 - March 2024</p>
              <ul className="list-disc list-inside text-gray-300 mt-2">
                <li>Designed and implemented a pipeline for generating advertisements using transfer learning models and semantic similarity. The solution automates ad generation for social media campaigns, deployed on AWS.</li>
                <li>Leveraged time series forecasting (Prophet) to predict ad campaign performance across platforms like Facebook, Google Ads, and Twitter in real-time.</li>
                <li>Built advanced neural network models (e.g., LSTM, GANs) to predict asset performance, improving campaign results.</li>
                <li>Developed a recommendation system to identify similar live campaign ads using mix marketing models.</li>
                <li>Created and maintained Tableau dashboards, tailoring them to meet dynamic business analytics needs.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-medium text-white">Professional Product Developer-DS</h3>
              <p className="text-gray-400">DXC Technologies | November 2018 - February 2020</p>
              <ul className="list-disc list-inside text-gray-300 mt-2">
                <li>Extracted and preprocessed life insurance customer data from IBM i DB2 for predictive analysis and policy performance insights.</li>
                <li>Conducted exploratory data analysis and developed predictive models to assess customer behavior and claims settlement patterns.</li>
                <li>Identified anomalies in claims data using R-based anomaly detection techniques, providing detailed reports for further investigation.</li>
                <li>Designed churn prediction models for Nippon Life Insurance, enhancing client retention through real-time predictions.</li>
                <li>Built new screens within Tableau to support business requirements for a new insurance product launch.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-12 bg-gray-900 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
            <FaCode className="mr-2 text-gray-400" /> Key Skills
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-lg font-medium mb-2 text-white flex items-center">
                <FaCode className="mr-2" /> Programming Languages
              </h4>
              <ul className="list-disc list-inside text-gray-300">
                <li>Python, R, SQL (MySQL, DB2, PostgreSQL)</li>
                <li>Julia, Core Java, TypeScript</li>
                <li>Node.js, .NET MVC</li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-medium mb-2 text-white flex items-center">
                <FaBrain className="mr-2" /> ML & NLP
              </h4>
              <ul className="list-disc list-inside text-gray-300">
                <li>Statistical Analysis, Machine Learning</li>
                <li>Deep Learning, Natural Language Processing</li>
                <li>Time Series Forecasting, Transfer Learning</li>
              </ul>
            </div>
          </div>
          <div className="mt-4">
            <h4 className="text-lg font-medium mb-2 text-white flex items-center">
              <FaServer className="mr-2" /> Technologies & Tools
            </h4>
            <div className="flex flex-wrap gap-2">
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiTensorflow className="mr-1" /> TensorFlow</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiPytorch className="mr-1" /> PyTorch</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiScikitlearn className="mr-1" /> Scikit-learn</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiPandas className="mr-1" /> Pandas</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiNumpy className="mr-1" /> NumPy</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiMysql className="mr-1" /> MySQL</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiAmazon className="mr-1" /> AWS</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded"><SiTableau className="mr-1" /> Tableau</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded">PySpark</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded">Flask</span>
              <span className="flex items-center bg-gray-800 px-2 py-1 rounded">StreamLit</span>
            </div>
          </div>
        </section>

        <section className="mb-12 bg-gray-900 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
            <FaGraduationCap className="mr-2 text-gray-400" /> Education
          </h3>
          <div className="space-y-4">
            <div>
              <h4 className="text-lg font-medium text-white">PGP in Data Science & Big Data (2020-2021)</h4>
              <p className="text-gray-300">Aegis School of Data Science, in association with IBM, Mumbai</p>
            </div>
            <div>
              <h4 className="text-lg font-medium text-white">Bachelor of Engineering (2014-2018)</h4>
              <p className="text-gray-300">Mumbai University, SJCET (7.77 CGPA)</p>
            </div>
          </div>
        </section>

        <section className="bg-gray-900 p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
            <FaEnvelope className="mr-2 text-gray-400" /> Contact
          </h3>
          <div className="flex space-x-6 justify-center">
            <a href="https://github.com/shabbirg89" className="text-gray-300 hover:text-white transition-colors duration-200" target="_blank" rel="noopener noreferrer">
              <FaGithub size={32} />
            </a>
            <a href="https://www.linkedin.com/in/shabbir-governor-442842154/" className="text-gray-300 hover:text-white transition-colors duration-200" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={32} />
            </a>
            <a href="mailto:shabbirg89@gmail.com" className="text-gray-300 hover:text-white transition-colors duration-200">
              <FaEnvelope size={32} />
            </a>
            <a href="https://www.youtube.com/@shabbirgovernor" className="text-gray-300 hover:text-white transition-colors duration-200">
              <FaYoutube size={32} />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;