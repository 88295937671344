import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaYoutube, FaProjectDiagram, FaEnvelope, FaInfoCircle, FaNewspaper } from 'react-icons/fa';
import logoImage from '../assets/Preview.png';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && location.pathname === '/') {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (path: string) => {
    setIsOpen(false);
    if (path === '/') {
      navigate(path);
      window.scrollTo(0, 0);
    } else if (path.startsWith('#')) {
      if (location.pathname !== '/') {
        navigate('/' + path);
      } else {
        const element = document.querySelector(path);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      navigate(path);
    }
  };

  const navItems = [
    { path: '/', label: 'Home', icon: <FaHome className="inline-block mr-1" /> },
    { path: '#youtube', label: 'Youtube', icon: <FaYoutube className="inline-block mr-1" /> },
    { path: '#projects', label: 'Projects', icon: <FaProjectDiagram className="inline-block mr-1" /> },
    { path: '#contact', label: 'Contact', icon: <FaEnvelope className="inline-block mr-1" /> },
    { path: '/articles', label: 'Articles', icon: <FaNewspaper className="inline-block mr-1" /> }, // Articles link
    { path: '/about', label: 'About', icon: <FaInfoCircle className="inline-block mr-1" /> }, // About link moved after Articles
  ];

  return (
    <nav className="bg-black p-4 fixed w-full z-50 top-0 shadow-lg border-b-2 border-gray-800">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 overflow-hidden rounded-full bg-white flex items-center justify-center p-0">
            <img src={logoImage} alt="Logo" className="w-full h-full object-cover" />
          </div>
          <h1
            className="text-white font-bold text-xl md:text-2xl hover:text-gray-300 transition duration-300 cursor-pointer"
            onClick={() => handleNavClick('/')}
          >
            Let's make it happen🚀🚀
          </h1>
        </div>
        <div className="hidden md:flex space-x-6">
          {navItems.map((item) => (
            <button
              key={item.path}
              className="text-white hover:text-gray-300 flex items-center transition duration-300"
              onClick={() => handleNavClick(item.path)}
            >
              {item.icon}
              <span className="ml-1">{item.label}</span>
            </button>
          ))}
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none transition duration-300">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} bg-black w-full mt-4 transition-all duration-300 ease-in-out`}>
        <ul className="space-y-4 p-4">
          {navItems.map((item) => (
            <li key={item.path}>
              <button
                className="text-white hover:text-gray-300 block flex items-center w-full text-left transition duration-300"
                onClick={() => handleNavClick(item.path)}
              >
                {item.icon}
                <span className="ml-1">{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;