//src/components/TermsAndConditions
import React from 'react';
import { FaHandshake, FaUserCheck, FaCopyright, FaInfoCircle, FaMoneyBillWave, FaExchangeAlt, FaShieldAlt, FaBalanceScale, FaHistory } from 'react-icons/fa';

const PolicySection: React.FC<{ title: string; content: string; icon: React.ReactNode }> = ({ title, content, icon }) => (
  <div className="mb-8 bg-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className="text-yellow-400 mr-4">{icon}</div>
      <h2 className="text-2xl font-semibold text-yellow-300">{title}</h2>
    </div>
    <p className="text-gray-300">{content}</p>
  </div>
);

const TermsAndConditions: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-black to-gray-900 min-h-screen py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-12 text-white text-center">Terms and Conditions</h1>
        <div className="max-w-4xl mx-auto">
          <PolicySection
            icon={<FaHandshake size={24} />}
            title="Agreement to Terms"
            content="By using our website, you agree to these Terms and Conditions, our Privacy Policy, and any other legal notices published by us on the website."
          />
          <PolicySection
            icon={<FaUserCheck size={24} />}
            title="Use of Website"
            content="You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website."
          />
          <PolicySection
            icon={<FaCopyright size={24} />}
            title="Intellectual Property"
            content="The content of this website is protected by copyright, trademarks, database and other intellectual property rights. You may retrieve and display the content of the website on a computer screen, store such content in electronic form on disk (but not any server or other storage device connected to a network) or print one copy of such content for your own personal, non-commercial use, provided you keep intact all and any copyright and proprietary notices."
          />
          <PolicySection
            icon={<FaInfoCircle size={24} />}
            title="Product Information"
            content="While we strive to provide accurate product and pricing information, pricing or typographical errors may occur. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time without prior notice."
          />
          <PolicySection
            icon={<FaMoneyBillWave size={24} />}
            title="Payment and Pricing"
            content="All prices listed on the website are in Indian Rupees (INR). Payment can be made through the methods provided on the website. By making a purchase, you warrant that you are legally capable of entering into binding contracts."
          />
          <PolicySection
            icon={<FaExchangeAlt size={24} />}
            title="Refunds and Returns"
            content="Please refer to our Refund Policy for information about refunds and returns. The Refund Policy is incorporated by reference into these Terms and Conditions."
          />
          <PolicySection
            icon={<FaShieldAlt size={24} />}
            title="Limitation of Liability"
            content="To the fullest extent permitted by law, we exclude all liability for any loss or damage arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits, damage caused to your computer, computer software, systems and programs and the data thereon."
          />
          <PolicySection
            icon={<FaBalanceScale size={24} />}
            title="Governing Law"
            content="These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India."
          />
          <PolicySection
            icon={<FaHistory size={24} />}
            title="Changes to Terms"
            content="We reserve the right to modify these terms at any time. Your continued use of the website after changes are posted constitutes your acceptance of the modified terms. We advise you to regularly check this page for any updates."
          />
        </div>
        <p className="text-center text-gray-400 mt-12">Last updated: {new Date().toDateString()}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;