//frontend/src/components/PaymentPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { PROJECTS, Project } from '../projectConstants';
import axios from 'axios';

declare global {
  interface Window {
    Razorpay: any;
  }
}

const PaymentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const project: Project | undefined = id ? PROJECTS[Number(id)] : undefined;
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';

    if (id) {
      const paidProjects = JSON.parse(localStorage.getItem('paidProjects') || '{}');
      if (paidProjects[id] && paidProjects[id].paid) {
        setIsPaid(true);
        setDownloadLink(paidProjects[id].downloadLink);
      }
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [id]);

  if (!project || !id) {
    return <div className="text-white text-center py-20">Project not found</div>;
  }

  const handlePaymentSuccess = (response: any) => {
    alert("Payment successful: " + response.razorpay_payment_id);
    const paidProjects = JSON.parse(localStorage.getItem('paidProjects') || '{}');
    paidProjects[id] = {
      paid: true,
      downloadLink: project.downloadLink
    };
    localStorage.setItem('paidProjects', JSON.stringify(paidProjects));
    setIsPaid(true);
    setDownloadLink(project.downloadLink);
    setIsLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/create-order`, {
        amount: project.price * 100, // Razorpay expects amount in paise
      });

      const { data } = response;
      const isProduction = process.env.NODE_ENV === 'production';
      const razorpayKeyId = isProduction
        ? process.env.REACT_APP_RAZORPAY_KEY_ID_LIVE
        : process.env.REACT_APP_RAZORPAY_KEY_ID_TEST;

      console.log('Using Razorpay Key:', razorpayKeyId);

      const options = {
        key: razorpayKeyId as string,
        amount: data.amount,
        currency: "INR",
        name: "Your Company Name",
        description: `Payment for ${project.name}`,
        order_id: data.id,
        handler: handlePaymentSuccess,
        prefill: {
          name: username,
          email: email,
        },
        notes: {
          address: "Your Address"
        },
        theme: {
          color: "#3498db"
        },
        modal: {
          ondismiss: function () {
            setIsLoading(false);
          }
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(`Error: ${error.response?.data?.error || error.message}`);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      setIsLoading(false);
    }
  };

  if (isPaid) {
    return (
      <div className="bg-gray-900 min-h-screen py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-8 text-white">Thank you for purchasing {project.name}</h1>
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md mx-auto">
            <p className="text-white mb-4">Your payment has been processed successfully.</p>
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full text-center bg-green-500 text-white px-5 py-3 rounded-full hover:bg-green-600 transition-colors duration-300 mb-4"
            >
              Download Project Files
            </a>
            <Link
              to={`/project/${id}`}
              className="block w-full text-center bg-blue-500 text-white px-5 py-3 rounded-full hover:bg-blue-600 transition-colors duration-300"
            >
              Back to Project Details
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 min-h-screen py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-white">Purchase {project.name}</h1>
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md mx-auto">
          {error && (
            <div className="bg-red-500 text-white p-3 rounded mb-4">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="username" className="block text-white mb-2">Enter your name</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-white mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <div>
              <p className="text-white mb-2">Amount: ₹{project.price}</p>
            </div>
            <button
              type="submit"
              className={`w-full px-5 py-3 rounded-full transition-colors duration-300 ${isLoading
                ? 'bg-gray-500 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
                } text-white`}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...Please Wait' : 'Pay Now'}
            </button>
          </form>
          <button
            onClick={() => navigate(`/project/${id}`)}
            className="w-full mt-4 bg-gray-700 text-white px-5 py-2 rounded-full hover:bg-gray-600 transition-colors duration-300"
          >
            Back to Project Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
