// src/components/Article.tsx
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaClock, FaUser, FaCalendar } from 'react-icons/fa';
import { ARTICLES } from '../projectConstants';

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const article = ARTICLES.find(a => a.id === id);

  if (!article) {
    return (
      <div className="bg-gray-100 min-h-screen pt-16">
        <div className="container mx-auto px-4 py-6">
          <h1 className="text-2xl font-bold text-gray-800">Article not found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen pt-16">
      <div className="container mx-auto px-4 py-6">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <img src={article.coverImage} alt={article.title} className="w-full h-48 object-cover" />
          <div className="p-4">
            <div className="flex items-center mb-3">
              <Link to="/articles" className="flex items-center text-blue-600 hover:text-blue-800 font-medium text-sm">
                <FaArrowLeft className="mr-1" size={12} />
                Back to Articles
              </Link>
            </div>
            <h1 className="text-2xl font-bold mb-2 text-gray-800">{article.title}</h1>
            <div className="flex flex-wrap items-center text-xs text-gray-600 mb-3">
              <span className="mr-3 flex items-center">
                <FaUser className="mr-1" size={12} /> {article.author}
              </span>
              <span className="mr-3 flex items-center">
                <FaCalendar className="mr-1" size={12} /> {article.date}
              </span>
              <span className="flex items-center">
                <FaClock className="mr-1" size={12} /> {article.readTime}
              </span>
            </div>
            <div
              className="prose prose-sm max-w-none"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;