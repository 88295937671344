//frontent/src/components/Hero.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FaPython, FaReact, FaDatabase, FaBrain, FaCode, FaRocket, FaChartBar } from 'react-icons/fa';
import { SiApachespark, SiMysql } from 'react-icons/si';
import logoImage from '../assets/Preview.png';
import backgroundImage from '../assets/shabbir4.jpg';

const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

interface SkillBadgeProps {
  icon: React.ReactNode;
  text: string;
}

const SkillBadge: React.FC<SkillBadgeProps> = ({ icon, text }) => (
  <div className="bg-blue-500 bg-opacity-20 backdrop-filter backdrop-blur-lg text-white px-2 py-1 rounded-full flex items-center justify-center text-xs sm:text-sm">
    {icon}
    <span className="ml-1">{text}</span>
  </div>
);

const Hero: React.FC = () => (
  <section
    id="home"
    className="relative min-h-screen flex flex-col justify-center items-center p-4 sm:p-8"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className="absolute inset-0 bg-black opacity-70"></div>
    
    <div className="container mx-auto z-10 relative flex flex-col items-center max-w-4xl mt-16 sm:mt-24">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-4 sm:mb-6 text-white animate-fade-in-up text-center">
        Data Science & Software Solutions
      </h1>
      
      <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-full overflow-hidden border-2 sm:border-4 border-blue-500 shadow-lg bg-black mb-4 sm:mb-6 animate-fade-in-up animation-delay-200">
        <img src={logoImage} alt="Shabbir Governor Logo" className="w-full h-full object-cover" />
      </div>
      
      <p className="text-base sm:text-lg md:text-xl lg:text-2xl mb-4 sm:mb-6 text-white animate-fade-in-up animation-delay-400 text-center">
        Transforming complex data into actionable insights
      </p>
      
      <div className="flex flex-wrap justify-center gap-2 sm:gap-3 mb-4 sm:mb-6 animate-fade-in-up animation-delay-600">
        <SkillBadge icon={<FaPython className="text-xs sm:text-sm" />} text="Python" />
        <SkillBadge icon={<FaReact className="text-xs sm:text-sm" />} text="React" />
        <SkillBadge icon={<FaDatabase className="text-xs sm:text-sm" />} text="Big Data" />
        <SkillBadge icon={<FaBrain className="text-xs sm:text-sm" />} text="ML/AI" />
        <SkillBadge icon={<FaCode className="text-xs sm:text-sm" />} text="Full Stack" />
        <SkillBadge icon={<FaRocket className="text-xs sm:text-sm" />} text="DevOps" />
        <SkillBadge icon={<SiApachespark className="text-xs sm:text-sm" />} text="PySpark" />
        <SkillBadge icon={<SiMysql className="text-xs sm:text-sm" />} text="SQL" />
        <SkillBadge icon={<FaChartBar className="text-xs sm:text-sm" />} text="Data Viz" />
      </div>
      
      <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4 animate-fade-in-up animation-delay-800 w-full sm:w-auto">
        <button
          onClick={() => scrollToSection('youtube')}
          className="bg-black text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition duration-300 border border-gray-300 shadow-md hover:shadow-lg transform hover:scale-105 text-sm sm:text-base"
        >
          Programming Tutorials
        </button>
        <button
          onClick={() => scrollToSection('projects')}
          className="bg-black text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition duration-300 border border-gray-300 shadow-md hover:shadow-lg transform hover:scale-105 text-sm sm:text-base"
        >
          Explore Premium Projects
        </button>
        <Link
          to="/articles"
          className="bg-black text-white font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition duration-300 border border-gray-300 shadow-md hover:shadow-lg transform hover:scale-105 text-sm sm:text-base text-center"
        >
          Read Tech Articles
        </Link>
      </div>
    </div>
  </section>
);

export default Hero;