//src/components/RefundPolicy.tsx
import React from 'react';
import { FaRegClock, FaRegClipboard, FaRegCalendarAlt, FaRegTimesCircle, FaRegWindowClose, FaCreditCard, FaRegEnvelope } from 'react-icons/fa';

const PolicySection: React.FC<{ title: string; content: string; icon: React.ReactNode }> = ({ title, content, icon }) => (
  <div className="mb-8 bg-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className="text-green-400 mr-4">{icon}</div>
      <h2 className="text-2xl font-semibold text-green-300">{title}</h2>
    </div>
    <p className="text-gray-300">{content}</p>
  </div>
);

const RefundPolicy: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-black to-gray-900 min-h-screen py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-12 text-white text-center">Refund Policy</h1>
        <div className="max-w-4xl mx-auto">
          <PolicySection
            icon={<FaRegClock size={24} />}
            title="Eligibility for Refunds"
            content="Refunds are available for products within 15 days of the original purchase date, provided the product is in its original condition and packaging."
          />
          <PolicySection
            icon={<FaRegClipboard size={24} />}
            title="Refund Process"
            content="To initiate a refund, please contact our customer service team at shabbirg89@gmail.com. Once your refund request is approved, we will process the refund to your original method of payment."
          />
          <PolicySection
            icon={<FaRegCalendarAlt size={24} />}
            title="Refund Timeline"
            content="Refunds are typically processed within 5-7 working days after the approval of your refund request. The time it takes for the refunded amount to appear in your account may vary depending on your payment provider or bank."
          />
          <PolicySection
            icon={<FaRegTimesCircle size={24} />}
            title="Non-Refundable Items"
            content="Certain items, such as downloadable digital products that have already been accessed or downloaded, are non-refundable unless required by law."
          />
          <PolicySection
            icon={<FaRegWindowClose size={24} />}
            title="Cancellations"
            content="Orders can be cancelled for a full refund if the cancellation is made before the product is shipped or, in the case of digital products, before the product is downloaded or access is granted."
          />
          <PolicySection
            icon={<FaCreditCard size={24} />}
            title="Refund Method"
            content="Refunds will be credited to the original method of payment used for the purchase. For credit card payments, refunds may take 5-7 working days to appear on your statement."
          />
          <PolicySection
            icon={<FaRegEnvelope size={24} />}
            title="Contact Us"
            content="If you have any questions about our Refund Policy, please contact us at shabbirg89@gmail.com."
          />
        </div>
        <p className="text-center text-gray-400 mt-12">Last updated: {new Date().toDateString()}</p>
      </div>
    </div>
  );
};

export default RefundPolicy;