//src/components/PrivacyPolicy.tsx
import React from 'react';
import { FaShieldAlt, FaInfoCircle, FaUserLock, FaLock, FaCookie, FaUserCog, FaHistory, FaEnvelope } from 'react-icons/fa';

const PolicySection: React.FC<{ title: string; content: string; icon: React.ReactNode }> = ({ title, content, icon }) => (
  <div className="mb-8 bg-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <div className="text-blue-400 mr-4">{icon}</div>
      <h2 className="text-2xl font-semibold text-blue-300">{title}</h2>
    </div>
    <p className="text-gray-300">{content}</p>
  </div>
);

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-black to-gray-900 min-h-screen py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold mb-12 text-white text-center">Privacy Policy</h1>
        <div className="max-w-4xl mx-auto">
          <PolicySection
            icon={<FaShieldAlt size={24} />}
            title="Information We Collect"
            content="We collect information that you provide directly to us when you register on the site, place an order, or contact us. This may include your name, email address, phone number, and payment information."
          />
          <PolicySection
            icon={<FaInfoCircle size={24} />}
            title="How We Use Your Information"
            content="We use the information we collect to process your orders, communicate with you, improve our services, and comply with legal obligations."
          />
          <PolicySection
            icon={<FaUserLock size={24} />}
            title="Information Sharing and Disclosure"
            content="We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating our website, conducting our business, or servicing you."
          />
          <PolicySection
            icon={<FaLock size={24} />}
            title="Data Security"
            content="We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure."
          />
          <PolicySection
            icon={<FaCookie size={24} />}
            title="Cookies and Tracking Technologies"
            content="We use cookies and similar tracking technologies to track activity on our site and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent."
          />
          <PolicySection
            icon={<FaUserCog size={24} />}
            title="Your Rights"
            content="You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights."
          />
          <PolicySection
            icon={<FaHistory size={24} />}
            title="Changes to This Privacy Policy"
            content="We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page."
          />
          <PolicySection
            icon={<FaEnvelope size={24} />}
            title="Contact Us"
            content="If you have any questions about this Privacy Policy, please contact us at shabbirg89@gmail.com."
          />
        </div>
        <p className="text-center text-gray-400 mt-12">Last updated: {new Date().toDateString()}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;