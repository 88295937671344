import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import YouTubePlaylist from './components/YouTubePlayList';
import Projects from './components/Projects';
import ProjectsPage from './components/ProjectsPage';
import ProjectDetails from './components/ProjectDetails';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import About from './components/About';
import PaymentPage from './components/PaymentPage';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import DigitalDeliveryPolicy from './components/DigitalDeliveryPolicy';
import ScrollToTop from './components/ScrolltoTop'; 
import ArticleList from './components/ArticleList';
import Article from './components/Article';

const Home: React.FC = () => (
  <>
    <Hero />
    <div id="youtube">
      <YouTubePlaylist />
    </div>
    <div id="projects">
      <Projects />
    </div>
    <Testimonials />
    <div id="contact">
      <ContactForm />
    </div>
  </>
);

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/project/:id" element={<ProjectDetails />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/payment/:id" element={<PaymentPage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/digital-delivery" element={<DigitalDeliveryPolicy />} />
            <Route path="/articles" element={<ArticleList />} />
            <Route path="/article/:id" element={<Article />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;